@import "variable.scss";

$bg-primary: #181818;
$bg-accent: #212121;
$btn-primary: #313131;
$dark: #313131;
$primary: #989898;
$link: #aaa;
$text: #fff;
$text-hover: #aaa;
$btn-border: #848f9a;

body {
    &.dark-theme {
        color: $text;
        background: $bg-primary;
    }
}

.dark-theme {
    @mixin title_block($line: true, $fs: 16px) {
        .all {
            color: $primary;
            i {
                color: $primary;
            }
        }
        @if $line == true {
            &:after {
                background-color: #aaa;
            }    
        }
    }

    h1, h2, h3, h4, h5, h6,
    .line div {
        color: #fff;
    }

    a {
        color: $primary;
        &:hover, &:focus {
            color: lighten($primary, 20%);
        }
        &.facebook {
            background-color: color($colors, facebook);
            color: #fff !important;
        }
        &.twitter {
            background-color: color($colors, twitter);
            color: #fff !important;
        }
        &.youtube {
            background-color: color($colors, youtube);
            color: #fff !important;
        }
        &.googleplus {
            background-color: color($colors, googleplus);
            color: #fff !important;
        }
        &.instagram {
            background-color: color($colors, instagram);
            color: #fff !important;
        }
        &.tumblr {
            background-color: color($colors, tumblr);
            color: #fff !important;
        }
        &.dribbble {
            background-color: color($colors, dribbble);
            color: #fff !important;
        }
        &.linkedin {
            background-color: color($colors, linkedin);
            color: #fff !important;
        }
        &.skype {
            background-color: color($colors, skype);
            color: #fff !important;
        }
        &.rss {
            background-color: color($colors, rss);
            color: #fff !important;
        }
        &.github {
            background-color: color($colors, github);
            color: #fff !important;
        }
        &.whatsapp {
            background-color: color($colors, whatsapp);
            color: #fff !important;
        }
        &.snapchat {
            background-color: color($colors, snapchat);
            color: #fff !important;
        }
        &.foursquare {
            background-color: color($colors, foursquare);
            color: #fff !important;
        }
        &.pinterest {
            background-color: color($colors, pinterest);
            color: #fff !important;
        }
        &.wordpress {
            background-color: color($colors, wordpress);
            color: #fff !important;
        }
        &.reddit {
            background-color: color($colors, reddit);
            color: #fff !important;
        }
        &.hackernews {
            background-color: color($colors, hackernews);
            color: #fff !important;
        }
        &.designernews {
            background-color: color($colors, designernews);
            color: #fff !important;
        }
        &.yahoo {
            background-color: color($colors, yahoo);
            color: #fff !important;
        }
        &.buffer {
            background-color: color($colors, buffer);
            color: #fff !important;
        }
        &.vimeo {
            background-color: color($colors, vimeo);
            color: #fff !important;
        }
        &.twitch {
            background-color: color($colors, twitch);
            color: #fff !important;
        }
        &.telegram {
            background-color: color($colors, telegram);
            color: #fff !important;
        }
    }

    .main-loading {
        background-color: #fff;
        box-shadow: 0 0 40px rgba(0,0,0,0.05);
        -webkit-box-shadow: 0 0 40px rgba(0,0,0,0.05);
        -moz-box-shadow: 0 0 40px rgba(0,0,0,0.05);
        -ms-box-shadow: 0 0 40px rgba(0,0,0,0.05);
        -o-box-shadow: 0 0 40px rgba(0,0,0,0.05);
    }

    .badge-primary {
        background-color: $bg-primary;
    }

    .badge-secondary {
        background-color: color($colors, secondary);
    }

    .badge-warning {
        background-color: color($colors, warning);
    }

    .badge-danger {
        background-color: color($colors, danger);
    }

    .badge-success {
        background-color: color($colors, success);
    }

    .badge-info {
        background-color: color($colors, info);
    }

    .badge-grey {
        background-color: color($colors, grey);
    }

    .breadcrumb {
        li {
            a {
                color: color($colors, primary);
            }
        }
    }

    .pagination {
        li {
            a {
                color: #fff;
                background-color: #f2f2f2;
            }
        }

        li {
            span {
                color: #fff;
                background-color: #f2f2f2;
            }
        }

        li.active {
            a {
                color: #fff;
                background-color: $bg-primary;
                &:hover, &:focus {
                    background-color: $bg-primary;
                }
            }
        }

        li.active {
            span {
                color: #fff;
                background-color: $bg-primary;
                &:hover, &:focus {
                    background-color: $bg-primary;
                }
            }
        }
    }

    .page-item {
        &.active .page-link {
            background-color: $bg-primary;
        }
    }

    .pagination-help-text {
        color: #666;
    }

    .backdrop {
        background-color: rgba(0,0,0,.5);
    }

    header.primary {
        &.up {
            nav.menu {
                box-shadow: 0 0 40px rgba(0,0,0,.1);
            }
        }
    }

    section {
        &.grey {
            background-color: color($colors, 'grey');
        }
        &.most-popular {
            .details {
                background-color: color($colors, 'mostpopular');
                .inner {
                    .badge {    
                        border: 2px solid color($colors, 'dark');
                        color: color($colors, 'dark');
                    }
                }
            }
        }
        &.not-found {
            .code {
                &:after, &:before {
                    background-color: #f2f2f2;
                }
            }
            p.lead {
                color: #999;
            }
        }
        &.search {
            .search-result {
                color: #999;
            }
        }
    }

    .form-control {
        &:focus {
            border-color: #989898;
            box-shadow: 0 1px 2px #ddd;
        }
    }

    .btn-social {
        &:hover {
            color: #fff;
        }
    }

    .btn-primary {
        color: #fff;
        background-color: #313131;
        border-color: #313131;
        &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
            background-color: lighten(#313131, 25%);
            border-color: lighten(#313131, 25%);
        }
        &:active:hover {
            background-color: lighten(#313131, 15%);
        }
    }

    .btn-success {
        background-color: color($colors, success);
        color: #fff !important;
        border-color: color($colors, success);
        &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
            background-color: darken(color($colors, success), 20%);
            border-color: darken(color($colors, success), 20%);
        }
        &:active:hover {
            background-color: darken(color($colors, success), 25%);
        }
    }

    .btn-warning {
        background-color: color($colors, warning);
        color: #fff !important;
        border-color: color($colors, warning);
        &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
            background-color: darken(color($colors, warning), 20%);
            border-color: darken(color($colors, warning), 20%);
        }
        &:active:hover {
            background-color: darken(color($colors, warning), 25%);
        }
    }

    .btn-danger {
        background-color: color($colors, danger);
        color: #fff !important;
        border-color: color($colors, danger);
        &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
            background-color: darken(color($colors, danger), 20%);
            border-color: darken(color($colors, danger), 20%);
        }
        &:active:hover {
            background-color: darken(color($colors, danger), 25%);
        }
    }

    .btn-info {
        background-color: color($colors, info);
        color: #fff !important;
        border-color: color($colors, info);
        &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
            background-color: darken(color($colors, info), 20%);
            border-color: darken(color($colors, info), 20%);
        }
        &:active:hover {
            background-color: darken(color($colors, info), 25%);
        }
    }

    .btn-default {
        background-color: color($colors, grey);
        color: #fff !important;
        border-color: color($colors, grey);
        &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
            background-color: darken(color($colors, grey), 20%);
            border-color: darken(color($colors, grey), 20%);
        }
        &:active:hover {
            background-color: darken(color($colors, grey), 25%);
        }
    }

    .btn-magz {
        background-color: transparent;
        color: color($colors, primary);
        border-color: color($colors, primary);
        &:after {
            background-color: $bg-primary;
        }
        &:focus {
            color: #fff;
        }
        &:hover {
            background-color: transparent;
            color: #fff !important;
        }
        &.white {
            color: #fff;
            &:after {
                background-color: #313131;
            }
            &:focus, &:hover {
                color: lighten(#313131, 25%) !important;
            }
        }
    }

    /* Social */

    .social {
        &.trp {
            li {
                a {
                    &:hover {
                        svg {
                            rect {
                                stroke: #fff;
                            }
                        }
                    }
                    &.facebook:hover {
                        color: color($colors, facebook) !important;
                        svg rect {
                            stroke: color($colors, facebook);
                        }
                    }
                    &.twitter:hover {
                        color: color($colors, twitter) !important;
                        svg rect {
                            stroke: color($colors, twitter);
                        }
                    }
                    &.youtube:hover {
                        color: color($colors, youtube) !important;
                        svg rect {
                            stroke: color($colors, youtube);
                        }
                    }
                    &.googleplus:hover {
                        color: color($colors, googleplus) !important;
                        svg rect {
                            stroke: color($colors, googleplus);
                        }
                    }
                    &.instagram:hover {
                        color: color($colors, instagram) !important;
                        svg rect {
                            stroke: color($colors, instagram);
                        }
                    }
                    &.tumblr:hover {
                        color: color($colors, tumblr) !important;
                        svg rect {
                            stroke: color($colors, tumblr);
                        }
                    }
                    &.dribbble:hover {
                        color: color($colors, dribbble) !important;
                        svg rect {
                            stroke: color($colors, dribbble);
                        }
                    }
                    &.linkedin:hover {
                        color: color($colors, linkedin) !important;
                        svg rect {
                            stroke: color($colors, linkedin);
                        }
                    }
                    &.skype:hover {
                        color: color($colors, skype) !important;
                        svg rect {
                            stroke: color($colors, skype);
                        }
                    }
                    &.rss:hover {
                        color: color($colors, rss) !important;
                        svg rect {
                            stroke: color($colors, rss);
                        }
                    }
                    &.github:hover {
                        color: color($colors, github) !important;
                        svg rect {
                            stroke: color($colors, github);
                        }
                    }
                    &.whatsapp:hover {
                        color: color($colors, whatsapp) !important;
                        svg rect {
                            stroke: color($colors, whatsapp);
                        }
                    }
                    &.snapchat:hover {
                        color: color($colors, snapchat) !important;
                        svg rect {
                            stroke: color($colors, snapchat);
                        }
                    }
                    &.foursquare:hover {
                        color: color($colors, foursquare) !important;
                        svg rect {
                            stroke: color($colors, foursquare);
                        }
                    }
                    &.pinterest:hover {
                        color: color($colors, pinterest) !important;
                        svg rect {
                            stroke: color($colors, pinterest);
                        }
                    }
                    &.wordpress:hover {
                        color: color($colors, wordpress) !important;
                        svg rect {
                            stroke: color($colors, wordpress);
                        }
                    }
                    &.reddit:hover {
                        color: color($colors, reddit) !important;
                        svg rect {
                            stroke: color($colors, reddit);
                        }
                    }
                    &.hackernews:hover {
                        color: color($colors, hackernews) !important;
                        svg rect {
                            stroke: color($colors, hackernews);
                        }
                    }
                    &.designernews:hover {
                        color: color($colors, designernews) !important;
                        svg rect {
                            stroke: color($colors, designernews);
                        }
                    }
                    &.yahoo:hover {
                        color: color($colors, yahoo) !important;
                        svg rect {
                            stroke: color($colors, yahoo);
                        }
                    }
                    &.buffer:hover {
                        color: color($colors, buffer) !important;
                        svg rect {
                            stroke: color($colors, buffer);
                        }
                    }
                    &.vimeo:hover {
                        color: color($colors, vimeo) !important;
                        svg rect {
                            stroke: color($colors, vimeo);
                        }
                    }
                    &.twitch:hover {
                        color: color($colors, twitch) !important;
                        svg rect {
                            stroke: color($colors, twitch);
                        }
                    }
                    &.telegram:hover {
                        color: color($colors, telegram) !important;
                        svg rect {
                            stroke: color($colors, telegram);
                        }
                    }
                }
            }
        }
    }

    .form-group {
        label {
            .required {
                color: color($colors, danger);
            }
        }
        .form-control {
            &:focus {
                border-color: #989898;
                box-shadow: 0 1px 2px #ddd;
            }
        }

        &.has-error {
            .form-control {
                border-color: color($colors, danger);
            }
            .control-label {
                color: color($colors, danger);
            }
            .help-block {
                color: color($colors, danger);
            }
        }

        &.has-success {
            .form-control {
                border-color: color($colors, success);
            }
            .control-label {
                color: color($colors, success);
            }
            .help-block {
                color: color($colors, success);
            }
        }

        &.has-warning {
            .form-control {
                border-color: color($colors, warning);
            }
            .control-label {
                color: color($colors, warning);
            }
            .help-block {
                color: color($colors, warning);
            }
        }

        &.floating {
            &.stacked {    
                label {
                    background-color: #fff;
                }
            }
            &.focus {
                .form-control {
                    background-color: transparent;
                }
            }
        }
    }

    .firstbar {
        background-color: $bg-accent;
        .search {
            .form-text {
                div {
                    color: $primary;
                }
                ul {
                    li {
                        a {
                            color: $text;
                        }
                    }
                }
            }
        }
        .nav-icons {
            li {
                a {
                    color: $text;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .headline {
        margin-bottom: 20px;
        .item {
            a {
                color: $text;
                .badge {
                    background-color: $primary;
                }
            }
        }
        .carousel-control {
            color: #fff;
            border: 1px solid #ddd;
            &.right {
                border-left: 0;
            }
        }
    }

    .article, article, .most-popular {
        p, ul {
            color: $text;
        }
    }

    .article {
        .inner {
            box-shadow: 0 1px 2px $dark;
        }
        h2 {
            a {
                color: #fff;
                &:hover {
                    color: $primary;
                }
            }
        }
    }

    article {
        .time, .category, .view {
            color: $primary;
        }
        .category {
            a {
                color: $primary;
            }
        }
        footer {
            .btn-primary {
                border-color: $primary;
                color: $primary;
                &:hover {
                    &:after {
                        background-color: $primary;
                    }
                    color: #fff;
                }
            }
        }
    }

    .love {
        color: #989898;
        &.active {
            color: color($colors, primary);
        }
    }

    .best-of-the-week {
        h1 {
            color: $text;
            div.text {
                background-color: $bg-primary;
            }
            &:after {
                background-color: $dark;
            }
            .carousel-nav {
                .next, .prev {
                    background-color: $bg-primary;
                }
            }
        }
        .article {
            h2 {
            a {
                color: #fff;
                &:hover {
                    color: $primary;
                }
            }
            }
        }
    }

    .featured {
        .overlay {
            background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.59) 100%);
            background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.59) 100%);
        }
        .details {
            h1, .category, .time {
                color: #fff;
            }
            .category {
                a {
                    background-color: #fff;
                    color: #fff;
                }
            }
            h1 {
                a {
                    color: #fff;
                }
            }
        }
    }

    .topbar {
        background-color: color($colors, topbar);
        .inner {
            border-bottom: 1px solid #ddd;
        }
        ul {
            li {
                a {
                    color: color($colors, dark);
                }
                a {
                    &:hover {
                        color: color($colors, primary);
                    }
                }
            }
        }
    }

    .menu {
        background-color: $bg-accent;
        border-top: 1px solid $dark;
        border-bottom: 1px solid $dark;

        ul {
            > li {
                > a {
                    color: $text;
                    .badge {
                        background-color: $primary;
                    }
                    &:after {
                        background-color: $primary;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0 20px 60px $dark;
                    &.megamenu {
                        background-color: $bg-accent;
                        .vertical-menu {
                            li {
                                a {
                                    border-bottom: 1px solid $dark;
                                }
                            }
                        }
                    }
                    > li {
                        &.divider {
                            background-color: #212121;
                            &:before {
                                background-color: #313131;
                            }
                        }
                        > a {
                            background-color: $bg-accent;
                        }
                        &.active a:after {
                            background-color: $bg-primary;
                        }
                        &:hover > a {
                            &:after {
                                background-color: $bg-primary;
                            }
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .newsletter {
        background-color: #212121;
        border: 1px solid #313131;
        color: #989898;
        .icon {
            &.success {
                h1 {
                    color: color($colors, success);
                }
                i:before {
                    color: color($colors, success);
                }
            }
            &.error {
                h1 {
                    color: color($colors, primary);
                }
                i:before {
                    color: color($colors, primary);
                }
            }
            h1 {
                color: #989898;
            }
        }
    }

    .article-list {
        .badge {
            background-color: $bg-primary;
        }
        figure {
            background-color: #fff;
        }
        .details {
            h1 {
                a {
                    color: #fff;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .line {
        background-color: #313131;
        div {
            background-color: $bg-primary;
            color: #fff;
        }
    }

    .title-col {
        &:after {
            background-color: $bg-primary;
        }
    }

    .article-mini {
        h1 {
            a {
                color: #fff;
            }
        }
        .detail {
            .category {
                a {
                    color: $primary;
                }
            }
        }
    }

    .tags-list {
        li {
            a {
                color: #fff;
                &:after {
                    background-color: $bg-primary;
                }
            }
        }
    }

    aside {
        .aside-title {
            @include title_block();
        }
        .nav li {
            a {
                background-color: #F0F0F0;
                color: #fff;
            }
            &.active a {
                background-color: $bg-primary;
                color: #fff;
                border-top: 2px solid #989898 !important;
                i {
                    color: $primary;
                }
            }
        }
        .tab-pane {
            border: 1px solid $dark;
        }

        .ads {
            figcaption {
                color: #989898;
            }
        }
    }

    .nav-tabs {
        border-bottom: 1px solid $dark;
        .nav-link {
            color: #aaa;
            &:focus,
            &:hover {
                border-color: #e9ecef #e9ecef #dee2e6;
            }
        }
        .nav-link.active,
        .nav-item.show .nav-link {
          color: $text;
          background-color: $bg-primary;
          border-color: $dark;
        }
    }

    .nav-link {
        &:focus,
        &:hover {
          color: color($colors, primary)
        }
    }

    .article-fw {
        .details {
            h1 {
                a {
                    color: #fff;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .comments {
        .detail {
            .link {
                a {
                    color: $primary;
                }
            }
            .time {
                color: #989898;
            }
            .divider {
                background-color: #ddd;
            }
        }
    }

    .box {
        background-color: #fff;
        box-shadow: 0 0 30px rgba(0,0,0,.05);
        &.box-border {
            border: 1px solid #f2f2f2;
        }
    }

    .title-line {
        color: #ddd;
        &:before, &:after {
            border-top: 2px dashed #eee;
        }
    }

    .with-sidebar {
        display: inline-block;
        width: 100%;
        .sidebar {
            &.sidebar-sticky {
                background-color: #fff;
            }
            .sidebar-nav {
                ul {
                    li {
                        a {
                            color: #fff;
                        }
                        &.active a, a:hover, &:focus {
                            background-color: #F73F52;
                            color: #fff;
                        }
                    }
                }
            }
        }
        footer {
            border-top: 1px solid #f9f9f9;
            color: #29292b;
        }
    }

    @media screen and (max-width: 768px) {
        .sidebar {
            background-color: #fff;
            .sidebar-title {
                border-bottom: 1px solid #f2f2f2;
            }
        }

        nav.menu {
            ul.nav-list {
                background-color: #212121;
                > li {
                    &:first-child {
                        a {
                            border-bottom: 1px solid #f2f2f2;
                        }
                    }
                }
            }
        }
    }

    footer.footer {
        background-color: $bg-accent;
        color: $text;
        border-top: 5px solid $dark;
        a {
            color: color($colors, footerText);
        }
        .social {
            a {
                color: #fff;
            }
        }
        .line {
            background-color: #292929;
        }
        .block {
            .block-title {
                color: #fff;
                .right {
                    a {
                        color: #ddd;
                    }
                }
                &:after {
                    background-color: #aaa;
                }
            }
        }
        .footer-nav-horizontal {
            li {
                a {
                    &:hover {
                        color: color($colors, primary);
                    }
                }
            }
        }
        .footer-nav {
            li {
                a {
                    &:hover {
                        color: color($colors, primary);
                    }
                }
            }
        }

        .article-mini {
            .detail {    
                .time, .category a {
                    color: color($colors, footerText);
                }
            }
        }
        .tags {
            li {
                a {
                    color: color($colors, footerText);
                    border: 1px solid color($colors, footerText);
                    &:hover {
                        border-color: lighten(color($colors, footerText), 25%);
                        color: lighten(color($colors, footerText), 25%);
                    }
                }
            }
        }
        .copyright {
            color: color($colors, footerText);
            border-top: 1px solid #222;
            div {
                i {
                    color: color($colors, danger);
                }
            }
        }
        .article-mini {
            h1 {
                a {
                    color: #ddd;
                }
            }
            .detail .category a {
                color: color($colors, footerText);
            }
        }
        .btn-magz {
            border-color: #fff;
        }
    }

    // single
    section.single {
        article.main-article {
            header {
                .details {
                    color: #989898;
                    li {
                        a {
                            color: color($colors, primary);
                        }
                    }
                }
            }

            .main {
                a {
                    color: color($colors, primary);
                }

                p, ul {
                    color: #fff;
                }

            .featured {
                figure {
                    figcaption {
                        color: #686868;
                    }
                }
            }
        }
    }

    footer {
        .col-m:last-child {
            .love {
                border: 2px solid #989898;
                &.active {
                    background-color: $bg-primary;
                    color: #fff;
                    border-color: color($colors, primary);
                }
            }
        }
        .tags {
            li {
                a {
                    border: 1px solid #989898;
                    color: #fff;
                    &:hover {
                        border-color: #fff;
                        color: color($colors, primary);
                    }
                }
            }
        }
    }

        article.related {
            .detail {
                .time {
                    color: #989898;
                }
            }
        }
    }

    .sharing {
        ul {
            li {
                &.count {
                    color: color($colors, primary);
                    div {
                        color: #fff;
                    }
                }
                a {
                    &:hover {
                        color: #fff !important;
                    }
                }
            }
        }
    }

    .author {
        .details {
            .job {
                color: #898989;
            }
        }
    }

    .comments {
        .comment-list {
            .item {
                border: 1px solid #ddd;
                .user {
                    .details {
                        .time {
                            color: #999;
                        }
                    }
                }
            }

            &.sm {
                .item {
                    border-bottom: 1px solid #f2f2f2;
                }
            }
        }

        .form-control {
            &:focus {
                border-color: #484848;
            }
        }
    }

    .page {
        .page-description {
            color: #fff;
            h1 {
                color: #fff;
            }
        } 

        .element-block {
            .element-title {
                &:after {
                    background-color: $bg-primary;
                }
            }
            .colors {
                li {
                    &.primary {
                        background-color: $bg-primary;
                    }
                    &.secondary {
                        background-color: color($colors, secondary);
                    }
                    &.success {
                        background-color: color($colors, success);
                    }
                    &.warning {
                        background-color: color($colors, warning);
                    }
                    &.info {
                        background-color: color($colors, info);
                    }
                    &.danger {
                        background-color: color($colors, danger);
                    }
                }
            }
        }
    }

    .carousel-1 {
        .owl-dots {
            .owl-dot {
                &.active {
                    span {
                        background-color: $bg-primary;        
                    }
                }
            }   
        }
        .owl-nav {
            .owl-prev, .owl-next {
                &:hover {
                    background-color: $bg-primary;
                }
            }
        }
    }

    .video-list {
        li {
            a {
                figure {
                    background-color: #f2f2f2;
                    .duration {
                        background-color: #212121;
                        color: #fff;
                    }
                    .play {
                        background-color: #fff;
                        color: #fff;
                    }
                }
                .desc {
                    .title {
                        &.loading {
                            background-color: #f2f2f2;
                        }
                    }
                    .author {
                        color: #999;
                        &.loading {
                            background-color: #f2f2f2;
                        }
                    }
                }
            }
        }
    }

    .nav-tabs-group {
        border-bottom: 1px solid #ddd;
        .nav-tabs-list {
            li {
                a {
                    color: #999;
                    &:hover {
                        color: color($colors, dark);
                    }
                }
                &.active {
                    a {
                        color: color($colors, dark);
                        border-bottom: 2px solid color($colors, primary);
                    }
                }
            }
        }
    }

    .featured-author {
        .featured-author-cover {
            .badges {
                .badge-item {
                    color: #fff;
                    background-color: color($colors, dark);
                }
            }
            .featured-author-picture {
                border: 2px solid #fff;
            }
            .featured-author-info {
                .name {
                    color: #fff;
                }
                .desc {
                    color: #ddd;
                }
            }
        }
        .featured-author-body {
            box-shadow: 0 0 3px #ddd;
            background-color: #fff;
            .featured-author-count {
                border-bottom: 1px solid #f2f2f2;
                .item {
                    border-right: 1px solid #f2f2f2;
                    a {          
                        &:hover {
                            .name {
                                color: color($colors, secondary);
                            }
                        }      
                    }
                    .name {
                        color: color($colors, primary);
                    }
                    .value {
                        color: #666;
                    }
                }
            }
            .featured-author-quote {
                color: #999;
            }
            .featured-author-footer {
                a {
                    border-top: 1px solid #f2f2f2;
                }
            }
        }
    }

    .item-list-round {
        li {
            a {
                .more {
                    background-color: transparentize(#fff, .5);
                    color: #fff;
                }
            }
        }
    }

    .aside-title, .title-col, .best-of-the-week {
        .carousel-nav {
            .prev, .next {
                border: 2px solid $dark;
                color: #999;
                &:hover {
                    background-color: lighten(#313131, 15%);
                }
            }
            .prev {
                border-right: 0;
            }
        }
    }

    .video_list_youtube {
        .fancybox-caption-wrap {
            h4 {
            color: #fff;
            }
        }
    }

    .banner {
        &:after {
            color: #999;
        }
    }
}